import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import {
  Header,
  Footer,
  CardModel,
  SEO,
  DCXButton,
  InfoCard,
  Frame,
  CarouselNative,
  BudgetCard,
  LandingSearch,
} from "../components"
import { useAllBrands } from "../hooks"
import { CarContext, SearchContext, UIContext } from "../context"
import {
  urlWithSession,
  getBestSellingModels,
  getFilteredModels,
  getBudgetedPrice,
} from "../utils"
import {
  BRANDS_LOGOS,
  CATEGORIES,
  CLASSIFICATIONS,
  getStandardAnimationProps,
  FINANCING_MINS,
  BUDGET_FILTER,
} from "../constants"
import { Payment } from "../types"

import {
  SmarthponeImage,
  checkoutFinalImage,
  checkoutMoneyImage,
  checkoutDeliveryImage,
  HowDoesItWork2Image,
  FrameCarImage,
  FrameLensImage,
  FrameSCrossImage,
  FrameSwiftImage,
  FrameWingleImage,
  FurgonIcon,
  HatchbackIcon,
  SedanIcon,
  SportIcon,
  SuvIcon,
  TruckIcon,
  VanIcon,
  CloudImageMobile1,
  CloudImageMobile2,
  CloudImageDesktop,
  CloudImageDesktopBlurry1,
  CloudImageDesktopBlurry2,
} from "../assets"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded"

export default function LandingPage() {
  let {
    allModels: { nodes: allModels },
  } = useStaticQuery(query)
  const appContext = useContext(CarContext)
  const {
    financeData,
    modelsPricesStocks,
    setPayment,
    setDues,
    setPercentage,
    setPriceOrder,
  } = appContext
  const {
    openSearcher,
    filters,
    setFilters,
    resetFilters,
    setEstimatedBudgetFilter,
  } = useContext(SearchContext)
  const { openAppraiseDrawer } = useContext(UIContext)

  const [mostSoldCars] = useState(getBestSellingModels(allModels).slice(0, 3))
  const [standardAnimationProps] = useState(getStandardAnimationProps())

  // BudgetCard state
  const [BCpayment, setBCPayment] = useState(Payment.credit)
  const [BCdues, setBCDues] = useState(36)
  const [monthly, setMonthly] = useState(300000)
  const [firstPayment, setFirstPayment] = useState(2000000)
  const [budgetedPrice, setBudgetedPrice] = useState(0)
  const [firstPaymentBottleneck, setFirstPaymentBottleneck] = useState(false)
  const [sliderValues, setSliderValues] = useState([0, BUDGET_FILTER.max])
  const [modelsLength, setModelsLength] = useState(0)
  const [allBrands] = useAllBrands()

  const handleFilterClick = useCallback(
    property => values => {
      resetFilters()
      if (!values.includes("all")) {
        setFilters(prev => ({
          ...prev,
          [property]: prev[property].concat(values),
        }))
      }
      navigate(urlWithSession("/home"))
    },
    [setFilters, resetFilters]
  )

  // Reet filters and clean exit
  const navigateToHome = useCallback(() => {
    resetFilters()
    navigate(urlWithSession("/home"))
  }, [resetFilters])

  // Reset filters and clean exit to home. Open appraise drawer
  const navigateToAppraise = useCallback(() => {
    navigateToHome()
    openAppraiseDrawer()
  }, [navigateToHome, openAppraiseDrawer])

  const onBudgetCardSubmit = useCallback(() => {
    setPayment(BCpayment)
    if (BCpayment === Payment.cash) {
      setFilters(prev => ({ ...prev, budget: sliderValues }))
    } else {
      setEstimatedBudgetFilter([0, budgetedPrice])
      setDues(BCdues)
      setPercentage(FINANCING_MINS.percentage.credit)
    }
    setPriceOrder(true)
    navigate(urlWithSession("/home"))
  }, [
    BCpayment,
    BCdues,
    budgetedPrice,
    sliderValues,
    setFilters,
    setPriceOrder,
    setDues,
    setPercentage,
    setPayment,
    setEstimatedBudgetFilter,
  ])

  // Reset filters when entering this page
  useEffect(() => {
    resetFilters()
  }, [resetFilters])

  // Update budgetedPrice on params changes
  useEffect(() => {
    const result = getBudgetedPrice({
      monthly: monthly,
      dues: BCdues,
      firstPayment,
      financeData,
    })
    if (result) {
      setBudgetedPrice(result.budgetedPrice)
      setFirstPaymentBottleneck(result.firstPaymentBottleneck)
    } else setBudgetedPrice(0)
  }, [monthly, firstPayment, BCdues, financeData])

  // Update available models on budgetedPrice or filters changes
  useEffect(() => {
    const filteredModels = getFilteredModels(
      allModels,
      "",
      {
        ...filters,
        budget: BCpayment === Payment.cash ? sliderValues : [0, budgetedPrice],
      },
      modelsPricesStocks,
      BCpayment
    )
    setModelsLength(filteredModels?.length || 0)
  }, [
    allModels,
    budgetedPrice,
    filters,
    BCpayment,
    sliderValues,
    modelsPricesStocks,
  ])

  // /* SEARCHING HANDLING */
  // useEffect(() => {
  //   if (modelSearch) {
  //     // Build suggestions
  //     const filteredModels = getFilteredModels(
  //       allModels,
  //       modelSearch,
  //       filters,
  //       modelsPricesStocks,
  //       payment
  //     )
  //     // Set found models if found, best selling models if not
  //     if (filteredModels.length) {
  //       setSearchSuggestions(
  //         filteredModels.map(model => model.name).slice(0, 5)
  //       )
  //     } else {
  //       setSearchSuggestions(
  //         getBestSellingModels(allModels)
  //           .map(model => model.name)
  //           .slice(0, 5)
  //       )
  //     }
  //     openSearchSuggestions()
  //   } else {
  //     setSearchSuggestions([])
  //     // if (showSearchSuggestions) {
  //     //   closeSearchSuggestions()
  //     // }
  //   }
  // }, [
  //   allModels,
  //   modelSearch,
  //   filters,
  //   payment,
  //   modelsPricesStocks,
  //   setSearchSuggestions,
  //   openSearchSuggestions,
  //   closeSearchSuggestions,
  // ])

  const [isSsr, setIsSsr] = useState(true)
  const [showClouds, setShowClouds] = useState(false)
  useEffect(() => {
    setIsSsr(false)
  }, [])
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowClouds(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [setShowClouds])
  const firstRef = useRef(null)
  const secondRef = useRef(null)
  const firstRefPosition = firstRef.current
    ? firstRef.current.getBoundingClientRect()
    : {}
  const secondRefPosition = secondRef.current
    ? secondRef.current.getBoundingClientRect()
    : {}
  const scrollY = isSsr ? 0 : window.scrollY

  return (
    <>
      <SEO url="https://dercocenterx.cl"></SEO>
      <Header page="index" />
      <div className="landing-page">
        <div className="intro-section">
          <div className="content max-width-limit" {...standardAnimationProps}>
            <div className="clouds-container">
              <img
                src={CloudImageDesktop}
                alt=""
                className={`cloud desktop ${showClouds ? "show" : ""}`}
                style={{
                  left: firstRef.current ? firstRefPosition.x - 40 : 0,
                  top: firstRef.current
                    ? firstRefPosition.y - 52 - 42 + scrollY
                    : 0,
                }}
              />
              <img
                src={CloudImageDesktopBlurry1}
                alt=""
                className={`cloud desktop second ${showClouds ? "show" : ""}`}
                style={{
                  left: secondRef.current ? secondRefPosition.x - 30 : 0,
                  top: secondRef.current
                    ? secondRefPosition.y -
                      52 +
                      secondRefPosition.height -
                      180 +
                      scrollY
                    : 0,
                }}
              />
              <img
                src={CloudImageDesktopBlurry2}
                alt=""
                className={`cloud desktop third ${showClouds ? "show" : ""}`}
                style={{
                  left: secondRef.current
                    ? secondRefPosition.x + secondRefPosition.width - 20 - 44
                    : 0,
                  top: secondRef.current
                    ? secondRefPosition.y -
                      52 +
                      secondRefPosition.height -
                      50 +
                      scrollY
                    : 0,
                }}
              />

              <img
                src={CloudImageMobile1}
                alt=""
                className={`cloud mobile first ${showClouds ? "show" : ""}`}
                style={{
                  left: -20,
                  top: firstRef.current ? firstRefPosition.y - 52 + scrollY : 0,
                }}
              />
              <img
                src={CloudImageMobile2}
                alt=""
                className={`cloud mobile second ${showClouds ? "show" : ""}`}
                style={{
                  right: -20,
                  top: firstRef.current
                    ? firstRefPosition.y -
                      52 +
                      firstRefPosition.height -
                      17 -
                      15 +
                      scrollY
                    : 0,
                }}
              />
            </div>
            <div className="content-container" ref={secondRef}>
              <div className="text-container">
                <span className="intro-title" ref={firstRef}>
                  La forma más fácil de comprar tu auto
                </span>
                <span className="intro-subtitle">
                  Arma tu auto online y elige dónde quieres recibirlo
                </span>
                <div
                  className="fake-searcher"
                  onClick={openSearcher}
                  role="button"
                  tabIndex={0}
                  onKeyDown={e => e.key === "Enter" && openSearcher()}
                >
                  <span className="text">Busca tu auto nuevo</span>
                  <SearchRoundedIcon className="icon" />
                </div>
                <LandingSearch allModels={allModels} />
              </div>

              <div className="smartphone-container">
                <img
                  src={SmarthponeImage}
                  alt=""
                  className="smartphone-image"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-section max-width-limit">
          <span className="section-title" {...standardAnimationProps}>
            Busca tu próximo auto
          </span>
          <span className="section-subtitle" {...standardAnimationProps}>
            Según el presupuesto, marca o categoría que prefieras
          </span>

          <div className="subsection">
            <span className="carousel-label" {...standardAnimationProps}>
              Por marca
            </span>
            <CarouselNative
              elements={[
                {
                  text: "Todas las marcas",
                  value: "all",
                },
              ].concat(
                allBrands.map(brand => ({
                  value: brand.name,
                  image: BRANDS_LOGOS[brand.ID],
                }))
              )}
              handleClick={handleFilterClick("brand")}
            />
          </div>
          <div className="subsection">
            <span className="carousel-label" {...standardAnimationProps}>
              Por categoría
            </span>
            <CarouselNative
              elements={CATEGORIES_CAROUSEL}
              handleClick={handleFilterClick("category")}
            />
          </div>
        </div>
        <div className="budget-section max-width-limit">
          <span className="desktop">Por presupuesto</span>

          <BudgetCard
            payment={BCpayment}
            setPayment={setBCPayment}
            dues={BCdues}
            setDues={setBCDues}
            monthly={monthly}
            setMonthly={setMonthly}
            firstPayment={firstPayment}
            setFirstPayment={setFirstPayment}
            budgetedPrice={budgetedPrice}
            firstPaymentBottleneck={firstPaymentBottleneck}
            sliderValues={sliderValues}
            setSliderValues={setSliderValues}
            modelsLength={modelsLength}
            onSubmit={onBudgetCardSubmit}
          />
        </div>
        <div className="assess-section">
          <div className="subsection">
            <div className="assess-mobile">
              <Frame
                key={"assess-frame"}
                image={HowDoesItWork2Image}
                title="Tasa tu auto aquí"
                desc="Recibe una oferta en línea"
                buttonText="TASA TU AUTO"
                buttonType="primary"
                handleClick={navigateToAppraise}
              />
            </div>
            <div className="assess-desktop max-width-limit">
              <div className="assess-left-container">
                <img src={HowDoesItWork2Image} alt="" />
              </div>
              <div className="assess-right-container">
                <span className="assess-title">
                  Tasa tu auto actual en línea
                </span>
                <span className="assess-subtitle">
                  Recibe una oferta en línea
                </span>
                <DCXButton
                  className="button long-button"
                  type="primary"
                  text="TASA TU AUTO"
                  onClick={navigateToAppraise}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="info-section">
          <div className="content max-width-limit" {...standardAnimationProps}>
            <span className="section-title">En DercoCenter X</span>
            <span className="section-subtitle">
              Encuentra el auto a tu medida
            </span>
            <div className="info-mobile">
              {INFO_CONTENT.map((infoCard, idx) => (
                <InfoCard
                  key={`$info-card-${idx}`}
                  idx={idx}
                  image={infoCard.image}
                  title={infoCard.title}
                  mainText={infoCard.mainText}
                />
              ))}
            </div>
            <div className="info-desktop">
              {INFO_CONTENT.map((frame, idx) => (
                <Frame
                  key={idx}
                  idx={idx}
                  image={frame.image}
                  title={frame.title}
                  desc={frame.mainText}
                />
              ))}
            </div>
            <div className="button-container" {...standardAnimationProps}>
              <DCXButton
                className="button long-button"
                type="primary"
                text="VER TODOS LOS AUTOS"
                onClick={navigateToHome}
              />
            </div>
          </div>
        </div>
        <div className="most-sold-section max-width-limit">
          <span className="section-title" {...standardAnimationProps}>
            Los más vendidos
          </span>
          <span className="section-subtitle" {...standardAnimationProps}>
            Modelos que vas a querer esta temporada
          </span>
          <div className="most-sold-container">
            {mostSoldCars.map((model, idx) => (
              <CardModel
                key={idx}
                idx={idx}
                model={model}
                appContext={appContext}
                terms={appContext.terms}
                animate={false}
              />
            ))}
          </div>
          <div className="button-container" {...standardAnimationProps}>
            <DCXButton
              className="button long-button most-sold-button"
              id="open-appraise-drawer-button"
              type="primary"
              text="VER MÁS AUTOS"
              onClick={navigateToHome}
            />
          </div>
        </div>
        <div className="type-filter-section">
          <div className="content max-width-limit">
            <span className="section-title">¿Qué tipo de auto buscas?</span>
            <span className="section-subtitle">
              Seleccionamos los mejores autos para ayudarte en tu decisión
            </span>
            <div className="frames-container">
              {CLASSIFICATION_FRAMES.map((frame, idx) => (
                <Frame
                  key={idx}
                  idx={idx}
                  image={frame.image}
                  title={frame.title}
                  desc={frame.desc}
                  value={frame.values}
                  buttonText={frame.buttonText}
                  buttonType={frame.buttonType}
                  handleClick={handleFilterClick("category")}
                  animate={false}
                />
              ))}
            </div>
          </div>
          <div className="help-section">
            <Frame
              image={FrameLensImage}
              title="¿No estás seguro del auto que buscas?"
              buttonText="TE AYUDAMOS A ENCONTRARLO"
              buttonType="primary"
            />
          </div>
          <div className="all-section max-width-limit">
            <Frame
              image={FrameCarImage}
              title="¿Estás listo para encontrar tu auto?"
              buttonText="VER AUTOS"
              buttonType="primary"
              handleClick={navigateToHome}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const CATEGORIES_CAROUSEL = [
  {
    text: "Todos los autos",
    value: CATEGORIES.all,
  },
  {
    text: "SUV",
    image: SuvIcon,
    value: CATEGORIES.suv,
  },
  {
    text: "Sedán",
    image: SedanIcon,
    value: CATEGORIES.sedan,
  },
  {
    text: "Hatchback",
    image: HatchbackIcon,
    value: CATEGORIES.hatchback,
  },
  {
    text: "Deportivo",
    image: SportIcon,
    value: CATEGORIES.sport,
  },
  {
    text: "Van",
    image: VanIcon,
    value: CATEGORIES.van,
  },
  {
    text: "Camioneta",
    image: TruckIcon,
    value: CATEGORIES.truck,
  },
  {
    text: "Furgón",
    image: FurgonIcon,
    value: CATEGORIES.furgon,
  },
]

const INFO_CONTENT = [
  {
    image: checkoutFinalImage,
    title: "Explora y arma tu nuevo auto",
    mainText: "Paga al contado o simula tu crédito y tasa tu auto actual",
  },
  {
    image: checkoutMoneyImage,
    title: "Sin regateos ni presiones",
    mainText:
      "Mostramos siempre el precio final incluyendo nuestros descuentos",
  },
  {
    image: checkoutDeliveryImage,
    title: "Prúebalo 7 días o 100kms",
    mainText: "Te lo llevamos a tu casa y si no te gusta ¡Lo devuelves!",
  },
]

const CLASSIFICATION_FRAMES = [
  {
    image: FrameSCrossImage,
    title: "Para ti y tu familia",
    desc: "Espaciosos y seguros",
    buttonText: "VER AUTOS",
    buttonType: "secondary",
    values: CLASSIFICATIONS.family,
  },
  {
    image: FrameSwiftImage,
    title: "Taquilleros de ciudad",
    desc: "Diseño y tecnología de vanguardia",
    buttonText: "VER AUTOS",
    buttonType: "secondary",
    values: CLASSIFICATIONS.city,
  },
  {
    image: FrameWingleImage,
    title: "Ideales para tu trabajo",
    desc: "Gran capacidad y fuerza",
    buttonText: "VER AUTOS",
    buttonType: "secondary",
    values: CLASSIFICATIONS.work,
  },
]

export const query = graphql`
  query FULL_QUERY_7 {
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
  }
`
